.ScriptHome {
  width: 80%;
  margin-left: 20px;
}

.ScriptCard {
  background-color: #262626;
  border-radius: 30px;
  color: white;
  font-size: 29px;
}

.ScriptActiveButton {
  width: 250px;
  height: 40px;
  font-size: 20px;
  background-color: #e0d324;
  border-radius: 5px;
  color: #383838;
  align-self: flex-end;
  cursor: pointer;
}

.ScriptTextArea {
  box-sizing: border-box;
  border: none;
  border-radius: 30px;
  width: 100%;
  height: 100px;
  background-color: #383838;
  resize: none;
  padding: 10px;
  font-size: 18px;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: underline;
}
.ScriptTextArea:focus {
  outline: none;
}

.ScriptSelectForm {
  background-color: transparent;
  color: white;
  border: none;
  font-size: 29px;
  text-indent: 30px;
  outline: none;
  /* appearance: none; */
  width: 220px;
}
.ScriptSelectForm option {
  background-color: white;
  color: black;
}

.ScriptCharactersDetails {
  background-color: #707070;
  height: 40px;
  border-radius: 15px;
  width: 160px;
}
.ScriptCharactersDetails:hover {
  cursor: pointer;
}

.ScriptCharactersDetailsActive {
  background-color: #e0d324;
  height: 40px;
  border-radius: 15px;
  width: 160px;
  color: black;
}
.ScriptCharactersDetailsActive:hover {
  cursor: pointer;
}

.ScriptProfile {
  width: 38px;
  height: 38px;
  border-radius: 30px;
}

.ScriptExtraButton2 {
  width: 250px;
  height: 40px;
  font-size: 20px;
  background-color: #e0d324;
  border-radius: 5px;
  color: #383838;
  align-self: flex-end;
  cursor: pointer;
}

.ScriptExtraButton {
  width: 250px;
  height: 40px;
  font-size: 20px;
  background-color: #e0d324;
  border-radius: 5px;
  color: #383838;
  align-self: flex-end;
  cursor: pointer;
  margin-left: 40px;
}

.ScriptExtraButtonDisable {
  width: 250px;
  height: 40px;
  font-size: 20px;
  background-color: #55522f;
  border-radius: 5px;
  color: #383838;
  align-self: flex-end;
  cursor: pointer;
  margin-left: 40px;
}

.ScriptEditButton {
  width: 200px;
  height: 40px;
  font-size: 20px;
  background-color: #262626;
  color: #e0d324;
  align-self: flex-end;
  cursor: pointer;
  margin-left: 40px;
  border: none;
  border-bottom: 2px solid #e0d324;
}

.ScriptTextAreaAI {
  box-sizing: border-box;
  border: none;
  border-radius: 10px;
  width: 100%;
  height: 350px;
  background-color: #383838;
  resize: none;
  padding: 10px;
  font-size: 18px;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: underline;
}
.ScriptTextAreaAI:focus {
  outline: none;
}

.ScriptModal {
  width: 1050px;
  height: 700px;
  background-color: rgb(255, 0, 0);
  /* margin-left: 0px; */
  /* margin-top: 115px; */
  border-radius: 40px;
}
.ScriptModalOverLay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  display: flex;
  align-items: center;
  justify-content: center;
}
/* ---------------------------- */
/* ---------------------------- */
/* ---------------------------- */
/* ---------------------------- */

.ScriptHome {
  width: 80%;
  margin-left: 20px;
}

.ScriptEditCard {
  background-color: #383838;
  border-radius: 10px;
  color: white;
  font-size: 29px;
  height: 750px;
}

.ScriptActiveButton {
  width: 250px;
  height: 40px;
  font-size: 20px;
  background-color: #e0d324;
  border-radius: 5px;
  color: #383838;
  align-self: flex-end;
  cursor: pointer;
}

.ScriptCharactersDetails {
  background-color: #707070;
  height: 40px;
  border-radius: 15px;
  width: 160px;
}

.ScriptCharactersDetailsActive {
  background-color: #e0d324;
  height: 40px;
  border-radius: 15px;
  width: 160px;
  color: black;
}

.ScriptProfile {
  width: 38px;
  height: 38px;
  border-radius: 30px;
}

.EditScriptCharactersChat {
  background-color: #000000;
  height: 77px;
  border-radius: 15px;
  color: white;
}

.EditScriptProfile {
  width: 50px;
  height: 50px;
  border-radius: 30px;
}

.EditTextCard {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 10px;
  max-height: 800px;
  height: 1600px;
  overflow-y: auto;
  padding: 10px;
  background-color: #383838;
  border-radius: 20px;
}

.EditTextCard::-webkit-scrollbar {
  width: 8px;
  /* margin-right: -100px; */
  height: 1600px;
}

.EditTextCard::-webkit-scrollbar-track {
  background-color: #262626;
}

.EditTextCard::-webkit-scrollbar-thumb {
  background-color: #707070;
}

.EditScriptEmotions {
  width: 100px;
  height: 20px;
  background-color: rgb(0, 0, 0);
  border-radius: 20px;
  color: #707070;
}

.EditScriptVideoCard {
  background-color: #262626;
  width: 100%;
  height: 700px;
  border-radius: 30px;
}

.ScriptVideo {
  border: #e0d324;
  width: 400px;
  height: 400px;
}

.EditScriptInput {
  background-color: #000000;
  color: white;
  width: 100%;
  font-size: 15px;
  /* height: 100%; */
  height: 100%;
  border: none;
  max-height: 3em; /* Adjust this value to the desired number of lines */
  /* overflow: auto;
  word-wrap: break-word;
  line-break: 200px; */
  /* display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap; */
}
.EditScriptInput:focus {
  outline: none;
}

.EditScriptInput::-webkit-scrollbar {
  width: 10px; /* Adjust the width of the scrollbar */
}

.EditScriptInput::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Adjust the background color of the track */
}

.EditScriptInput::-webkit-scrollbar-thumb {
  background-color: #888; /* Adjust the color of the scrollbar thumb */
}

.EditScriptInput::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Adjust the color of the scrollbar thumb on hover */
}

.ScriptAddTextButton {
  width: 50px;
  height: 50px;
  font-size: 15px;
  background-color: #e0d324;
  border-radius: 5px;
  color: #383838;
  align-self: flex-end;
  cursor: pointer;
  border-radius: 100px;
}

.ScriptActiveButtonGreen {
  width: 250px;
  height: 40px;
  font-size: 22px;
  background-color: #27b120;
  border-radius: 10px;
  color: #383838;
  align-self: flex-end;
  cursor: pointer;
}

.VideoHouse {
  background-color: #383838;
  width: 300px;
  height: 795px;
  border-radius: 20px;
}

.ScriptVideo {
  border: #e0d324;
  width: 396px;
  height: 300px;
}

.ScriptVideoButtons {
  background-color: #000000;
  height: 40px;
  width: 80%;
  border-radius: 20px;
}

.ScriptOfLastVideo {
  width: 400px;
  height: 500px;
  background-color: #383838;
  border-radius: 20px;
}
.ScriptVideoLast {
  width: 100%;
  height: 400px;
}

.ScriptBulb {
  width: 30px;
  height: 30px;
  pointer-events: auto;
}
.ScriptBulb:hover {
  cursor: pointer;
}
