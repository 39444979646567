.HomeCardDetails {
  background-color: #262626;
  /* height: 418px; */
  border-radius: 50px;
}

.SampleInputForm {
  /* font-size: 20px; */
  background-color: #383838;
  color: rgb(188, 183, 183);
  border: none;
  font-size: 20px;
  /* font-style: italic;
  text-decoration: underline; */
  text-indent: 5px;
  outline: none;
  /* width: 240px; */
  border-radius: 4px;
  height: 45px;
}

.SampleInputForm::placeholder {
  color: rgb(164, 157, 157); /* Replace "red" with your desired color */
}

.SampleActiveButton {
  width: 200x;
  height: 40px;
  font-size: 20px;
  background-color: #e0d324;
  border-radius: 10px;
  color: #383838;
  /* align-self: flex-end; */
  cursor: pointer;
}
