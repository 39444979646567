.HomeProfileIcon {
  width: 60px;
  height: 50px;
}

.HomeProjectLibrary {
  padding: 10px;
  border: 1px solid black;
  width: 10%;
  height: 90%;
}

.HomeNameProject {
  padding: 10px;
  border: 1px solid black;
}
.HomeDescribeProject {
  padding: 10px;
  width: 50%;
  border: 1px solid black;
}

/* -----------------------------------------*/

.SmapleProfileActive {
  width: 40px;
  height: 45px;
  border: 5px solid #e0d324;
}

.SmapleProfile {
  width: 145px;
  height: 165px;
  border: 5px solid #4b4b47;
}

.SmapleAvatarNameActive {
  color: #e0d324;
  font-family: Roboto;
  font-size: 18px;
  font-weight: lighter;
}
.SmapleAvatarName {
  color: #646464;
  font-family: Roboto;
  font-size: 18px;
  font-weight: lighter;
}

.HomeButton {
  width: 100px;
  height: 30px;
  background-color: #e0d324;
  border-radius: 10px;
  color: #383838;
  font-size: 20px;
  align-self: flex-end;
  cursor: pointer;
}

/* -----------------------------------------*/

.HomeCharacterDetails {
  width: 100%;
  height: 300px;
}

.HomecharacterModeButtonBox {
  width: 10000px;
}
