.SmapleProfile {
  width: 145px;
  height: 165px;
  border: 5px solid #4b4b47;
}
.SmapleProfileActive {
  width: 145px;
  height: 165px;
  border: 5px solid #e0d324;
}

.SmapleAvatarNameActive {
  color: #e0d324;
  font-family: Roboto;
  font-size: 18px;
  font-weight: lighter;
}

.SmapleAvatarName {
  color: #646464;
  font-family: Roboto;
  font-size: 18px;
  font-weight: lighter;
}

.SampleDetails {
  height: 100%;
  background-color: #262626;
  border: 5px solid #e0d324;
  border-radius: 30px;
}

.SampleFonts {
  background-color: transparent;
  color: white;
  border: none;
  font-size: 20px;
  font-weight: 500;
  /* font-style: italic;
  text-decoration: underline;
  text-indent: 5px;
  outline: none; */
}

.SampleFontsExtra {
  background-color: transparent;
  color: white;
  border: none;
  font-size: 20px;
  font-style: italic;
  text-decoration: underline;
  text-indent: 5px;
  outline: none;
  /* font-weight: bold; */
}

.SmapleTextArea {
  box-sizing: border-box;
  border: none;
  border-radius: 10px;
  width: 100%;
  height: 220px;
  background-color: #383838;
  resize: none;
  padding: 10px;
  font-size: 20px;
  color: white;
  font-style: italic;
  font-family: Arial, Helvetica, sans-serif;
}
.SmapleTextArea:focus {
  outline: none;
  color: #e0d324;
}

.SampleActiveButton {
  width: 250px;
  height: 40px;
  font-size: 22px;
  background-color: #e0d324;
  border-radius: 10px;
  color: #383838;
  align-self: flex-end;
  cursor: pointer;
}

.SampleButton {
  width: 250px;
  height: 40px;
  background-color: black;
  border-radius: 10px;
  color: #e0d324;
  font-size: 22px;
  align-self: flex-end;
  cursor: pointer;
  border: 3px solid #e0d324;
}

.circle {
  width: 27px;
  height: 27px;
  border-radius: 100%;
  border: 3px solid white;
  background-color: transparent;
  cursor: pointer;
}

.circleActive {
  width: 27px;
  height: 27px;
  border-radius: 100%;
  border: 3px solid white;
  background-color: white;
  cursor: pointer;
}

.SampleInputForm {
  /* font-size: 20px; */
  background-color: transparent;
  color: white;
  border: none;
  font-size: 20px;
  font-style: italic;
  text-decoration: underline;
  text-indent: 5px;
  outline: none;
  width: 240px;
}

.SampleSelectForm {
  background-color: transparent;
  color: white;
  border: none;
  font-size: 20px;
  font-style: italic;
  text-decoration: underline;
  text-indent: 5px;
  outline: none;
  appearance: none;
  width: 150px;
}
.SampleSelectForm option {
  background-color: white;
  color: black;
}

.SampleChooseContainer {
  background-color: #262626;
  border-radius: 30px;
  width: 1000px;
  height: 100%;
}

.SampleChooseImage {
  width: 90%;
  height: 90%;
  border-radius: 50%;
  /* border: 5px solid #e0d324; */
}
.SampleChooseImageSelected {
  width: 85%;
  height: 85%;
  border-radius: 50%;
  border: 5px solid #e0d324;
}

.SampleEditButton {
  background-color: #e0d324;
  /* border: 2px solid #e0d324; */
  color: #262626;
  width: 90%;
  height: 36px;
}

@media (max-width: 1750px) {
  .SmapleProfile {
    width: 120px;
    height: 145px;
  }
  .SmapleProfileActive {
    width: 120px;
    height: 145px;
  }
}

@media (max-width: 1350px) {
  .SmapleProfile {
    width: 100px;
    height: 125px;
  }
  .SmapleProfileActive {
    width: 100px;
    height: 125px;
  }
}
/* width: 125px;
height: 145px; */

.SampleActiveButtonGreen {
  width: 250px;
  height: 40px;
  font-size: 22px;
  background-color: #27b120;
  border-radius: 10px;
  color: #383838;
  align-self: flex-end;
  cursor: pointer;
}
