.HomeImage {
  width: 100%;
  height: 100%;
}

.HomeCard {
  background-color: rgb(0, 0, 0);
  height: 570px;
}

.HomeCardDetails {
  background-color: #262626;
  height: 418px;
  border-radius: 10px;
}

.HomeTextArea {
  border: none;
  border-radius: 10px;
  outline: none;
  width: 90%;
  height: 150%;
  background-color: #383838;
  resize: none;
  padding: 10px;
  font-size: 20px;
  color: white;
  font-style: italic;
  font-family: Arial, Helvetica, sans-serif;
}

.HomeButton {
  width: 250px;
  height: 51px;
  background-color: #e0d324;
  border-radius: 10px;
  color: #383838;
  font-size: 24px;
  align-self: flex-end;
  cursor: pointer;
}

/* .number-input {
  width: 1px;
  height: 45px;
  display: flex;
  align-items: center;
  border: 3px solid #3b3b3b;
} */

.HomeInput {
  height: 100%;
  padding: 0px;
  margin: 0px;
  border: none;
  background-color: transparent;
  color: white;
  text-align: center;
  font-size: 24px;
  width: calc(250px - 90px);
  outline: none;
}

.HomeSignNotActive {
  width: 45px;
  height: 45px;
  background-color: #3b3b3b;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  cursor: pointer;
  border: 3px solid #3b3b3b;
  color: #ffffff;
}

.HomeSign {
  width: 45px;
  height: 45px;
  background-color: #e0d324;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  cursor: pointer;
  color: #292929;
  border: none;
}

.HomeAddMinusBox {
  border: 3px solid #3b3b3b;
}

.Toastify__toast-container {
  /* Container styles */
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999;
}
