.headerContainer {
  width: 100%;
  height: 114px;
  background-color: #1c1c1c;
}

.headerImage {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.HomeButton {
  width: 80px;
  height: 40px;
  background-color: #e0d324;
  border-radius: 10px;
  color: #383838;
  font-size: 15px;
  align-self: flex-end;
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
}

.siginButton {
  width: 67px;
  height: 40px;
  background-color: transparent;
  border-radius: 10px;
  color: #e0d324;
  font-size: 17px;
  align-self: flex-end;
  cursor: pointer;
  border: none;
  text-decoration: none;
  font-weight: 600;
}
